(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:RefereeList
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('sancionscomite', sancionscomite);

  function sancionscomite($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/sancionscomite/:dataini/:datafi', {dataini: '@_dataini',datafi:'@_datafi'}, {
      update: {
        method: 'PUT'
      },
      query: {
        method: 'GET',
        isArray: true
      }
    });
  }
}());/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */


